/********************************************************
/* COMPONENTS/_CAROUSEL.SCSS
/*******************************************************/
$carousel-nav-button--width: 50px;

/********************************************************
/* COMPONENTS/_CAROUSEL.SCSS
/*******************************************************/
$carousel-nav-button--width: 50px;

#carousel_section {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;

  .content-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    .carousel-container {
      position: relative;
      width: 100%;
      height: 100%;

      .carousel {
        display: flex;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        position: relative;

        .carousel-item {
          min-width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
          background-size: cover;
          background-position: center;
          position: absolute;
          top: 0;
          z-index: -1;

          // Max Case Study
          //***************************/
          &#carousel-item-3 {
            position: relative;
            background-color: white;
            z-index: 1;
            align-items: flex-end;
            justify-content: center;
            @media all and (min-width: $screen-lg) {
              align-items: center;
              justify-content: flex-start;
            }

            // Wrapper for the background image
            .background-image {
              background-image: url($maxAppPromoImage);
              background-size: 60%;
              background-repeat: no-repeat;
              background-position: 70% 0%;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 0;
              @media all and (min-width: $screen-lg) {
                background-size: 50%;
                background-position: 80% 50%;
              }
            }

            &::after {
              content: '';
              position: absolute;
              top: 15%;
              left: 55%;
              transform: translateX(-50%);
              width: 200px;
              height: 200px;
              border-radius: 50%;
              background-color: $color-primary;
              z-index: -1;
              pointer-events: none;
              @media all and (min-width: $screen-lg) {
                width: 300px;
                height: 300px;
              }
            }

            .carousel-content {
              position: relative;
              z-index: 1;
              background-color: white;
              border-radius: $space-md;
              padding: $space-md $space-lg $space-xl $space-lg;
              width: 100%;
              @media all and (min-width: $screen-lg) {
                background-color: transparent;
                left: 80px;
                width: auto;
                padding: $space-md $space-lg;
              }
            }
          }
        }

        .carousel-item.active {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          z-index: 1;
        }

        .carousel-item.prev {
          opacity: 0;
          visibility: visible;
          transform: translateX(-100%);
        }

        .carousel-item.next {
          opacity: 0;
          visibility: visible;
          transform: translateX(100%);
        }

        .carousel-item:not(.active):not(.prev):not(.next) {
          opacity: 0;
          visibility: hidden;
          transform: translateX(0);
        }
      }
    }
  }

  

  .carousel-navigation {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 5%;
      height: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 5%;
      height: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    
    button {
      @extend .button-carousel-nav;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      &.back-button {
        left: $space-md;
      }
      &.next-button {
        right: $space-md;
      }
    }
  }

  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;

    .indicator {
      width: 10px;
      height: 5px;
      background-color: lightgray;
      margin: 0 5px;
      border-radius: 3px;
      transition: all 0.3s ease;

      &.active {
        width: 15px;
        height: 7px;
        background-color: $color-primary;
      }
    }
  }
}