/********************************************************
/* _BASE.SCSS
/********************************************************
This partial stylesheet overwrites base styles that 
do not require classes or id's
*********************************************************/
@font-face {
  font-family: "Sifonn";
  src: url("../../fonts/SIFONN_BASIC.otf");
}
html {
  scroll-behavior: smooth;
}
*{
  box-sizing: border-box;
}
a {
  color: $cw-green-1;
  &:hover {
    color: $cw-green-1;
    cursor: pointer;
  }
}
button{
  &:hover {
    cursor: pointer;
  }
}
body {
  font-family: $font-stack-primary;
  color: $color-text;
}

p {
  letter-spacing: 0.05em;
  line-height: 1.6;
  font-size: $font-md;
}

img {
  max-width: 100%;
}
section {
  position: relative;
  width: 100%;
}

ul, ol {
  font-size: $font-md;
  margin-bottom: 1rem;
}