/********************************************************
/* LAYOUT/_HEADER.SCSS
/*******************************************************/

#cw_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  background: transparent;
  pointer-events: none;

  &.active {
    background: your-background-color;
  }

  &.header-hidden {
    transform: translateY(-100%);
  }

  .logo {
    width: 80px;
    height: auto;
  }
  .navbar {
  // background-color: rgba($color-secondary,0);
  -webkit-transition: 2s;
  transition: 2s;
  padding: .5rem 1rem .5rem 0;
    a,
    button {
      pointer-events: auto;
    }
  }
  .navbar-brand {
    padding: 5px 5px 5px 20px;
    border-radius: 0 50% 50% 0;
    background-color: $color-primary;
    transition: all .35s ease-in-out;
    &:hover {
      background-color: $color-secondary;
    }
  }
  .navbar-nav {
    padding-top: 1rem;
    @media screen and (min-width: $screen-lg) {
      padding-top: 0;
      margin-left: auto;
    }
  }
  .nav-item {
    margin: 0 2px;
    a {
      padding: 10px 15px 13px 15px;
      color: white;
      background-color: $color-primary;
      font-family: $font-stack-secondary;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-left: 5px;
      transition: all .35s ease-in-out;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      width: 100%;
      &:hover {
        background-color: $color-secondary;
      }
    }
  }
  .navbar-toggler {
    border-radius: 0;
    background-color: $color-primary;
    &:hover {
      background-color: $color-secondary;
    }
    .navbar-toggler-icon {
      background-image: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
