/********************************************************
/* COMPONENTS/_COLORS.SCSS
/*******************************************************/

/* Change Color Attribute
***********************/
@each $color, $color-value in $colors {
  .color--#{$color} {
    color: $color-value;
  }
}

@each $color, $color-value in $colors {
  .bg-color--#{$color} {
    background-color: $color-value;
  }
}

@each $color, $color-value in $colors {
  .border-color--#{$color} {
    border-color: $color-value;
  }
}