/********************************************************
/* COMPONENTS/_TYPOGRAPHY.SCSS
/*******************************************************/

.font-size--xs {
  font-size: $font-xs;
}

.font-size--sm {
  font-size: $font-sm;
}

.font-size--md {
  font-size: $font-md;
}

.font-size--lg {
  font-size: $font-lg;
}

.font-size--xl {
  font-size: $font-xl;
}

.font-size--xxl {
  font-size: $font-xxl;
}

.font-1 {
  font-family: $font-stack-primary;
}

.font-2 {
  font-family: $font-stack-secondary;
}

.font-3 {
  font-family: $font-stack-tertiary;
}

.body-text {
  line-height: 1.75;
}

.section-heading {
  font-size: $font-xl;
  text-align: center;
  font-family: $font-stack-secondary;
  text-transform: uppercase;
}

h3 {
  font-size: $font-md;
}