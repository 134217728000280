
// Corporate Colors - HEX
//***************************/
$cw-green-1   : #308273;
$cw-green-2   : #29bda3;
$cw-orange  : #E86753;
$cw-neutral-1 : #0b111e;
$cw-darkPurple : #1a0112;
$cw-darkPurple2 : #182340;
$cw-darkBlue  : #162441;
$cw-gray : #777777;
$cw-gray-2 : #333333;
$white  : #ffffff;
$black  : #000000;


// Descriptive color variables
//***************************/
$color-primary    : $cw-green-1 !default;
$color-secondary  : $cw-darkPurple !default;
$color-tertiary   : $cw-darkBlue !default;
$color-success    : $cw-green-1 !default;
$color-info       : $cw-green-1 !default;
$color-warning    : $cw-orange !default;
$color-danger     : $cw-orange !default;
$color-lightGray: mix($cw-darkPurple, white, 15%);
$color-text : $cw-neutral-1 !default;
$color-white : #f2f2f2 !default;
$color-gray : $cw-gray !default;
$color-gray-2 : $cw-gray-2 !default;

// Color Map
//***************************/
$colors: (
  primary : $color-primary,
  secondary : $color-secondary,
  success : $color-success,
  info : $color-info,
  danger : $color-danger,
  warning : $color-warning,
  green : $cw-green-1,
  orange : $cw-orange,
  purple: $cw-darkPurple2,
  darkblue: $cw-darkBlue,
  neutral: $cw-neutral-1,
  lightGray: $color-lightGray,
  white: $white,
  black: $black,
  gray: $color-gray,
  gray-2: $color-gray-2,
);

// Breakpoints for media queries
//***************************/
$screen-sm  : 576px;
$screen-md  : 768px;
$screen-lg  : 992px;
$screen-xl  : 1200px;
$screen-xxl  : 1440px;

// Font Stacks
//***************************/
$font-stack-primary      :  'Raleway', Helvetica, Arial, sans-serif;
$font-stack-secondary    :  'Sifonn', sans-serif;
$font-stack-tertiary     :  'Merriweather', serif;
$font-stack-icon : 'Material Icons';

// Common Space Sizes
//***************************/
// Used for Margin/Padding
$space-xxs : 3px;
$space-xs : 5px;
$space-sm : 10px;
$space-md : 13px; //common size for bootstrap column padding
$space-lg : 30px;
$space-xl : 50px;
$space-xxl : 100px;

// Text Sizes
//***************************/
$font-xs : 0.8rem;
$font-sm : 1rem;
$font-md : 1.15rem;
$font-lg : 1.5rem;
$font-xl : 2rem;
$font-xxl : 4rem;

// Links
//***************************/
$color-link : $color-primary;
$color-link--hover : darken($color-primary, 10%);

// Case Studies
//***************************/
$maxAppPromoImage : '../../img/case-studies/cw-max-appPromoImage.png';  
