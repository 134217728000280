/********************************************************
/* COMPONENTS/_ICONS.SCSS
/*******************************************************/
a.social-link {
  display: inline-flex;
  margin: $space-md;
  height: 50px;
  &:hover .social-icon {
    filter: invert(75%) sepia(75%) saturate(468%) hue-rotate(106deg) brightness(98%) contrast(86%);
  }
  .social-icon {
    filter: invert(66%) sepia(14%) saturate(1878%) hue-rotate(120deg) brightness(92%) contrast(89%);
    width: 100%;
    max-width: 50px;
  }
}
