// MIXINS
// ------------------------------------/
@mixin skewed {
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 30%;
    position: absolute;
    transform: skewY(-2deg);
    @content;
  }
}

@mixin vertical-align($position: absolute) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@mixin max_outer-shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba($grey-4, 0.19);
  -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba($grey-4, 0.19);
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba($grey-4, 0.19);
}

%clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin c-fix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

%center-it { /* centers vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin themes($map) {
  @each $theme, $color in $map {
    &-#{$theme} {
      color: $color;
    }
  }
}