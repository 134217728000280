/********************************************************
/* COMPONENTS/_BANNERS.SCSS
/*******************************************************/

// Descriptive color variables
//***************************/
$parallax-1 : '../../img/parallax/cw_parallax_layer1.png';
$parallax-2 : '../../img/parallax/cw_parallax_layer2.png';
$parallax-3 : '../../img/parallax/cw_parallax_layer3.png';
$parallax-4 : '../../img/parallax/cw_parallax_layer4.png';
$parallax-5 : '../../img/parallax/cw_parallax_layer5.png';
$parallax-mobile : '../../img/parallax/cw_parallax_banner_mobile.png';
$parallax-full : '../../img/parallax/cw_parallax_banner_full.png';


/* Page Banners
***********************/
#banner_section {
  background-color: #f7f7f7;
  height: 100vh;
  width: 100%;
}
.banner-title {
  font-size: 4vw;
  margin: 0;
  &--sm {
    font-size: 3vw;
  }
  &--md {
    font-size: 4vw;
  }
  &--lg {
    font-size: 6vw;
  }
}
.banner-subtitle {
  font-size: 1.5vw;
  font-style: italic;
  &--sm {
    font-size:1vw;
    font-style: italic;
  }
  &--lg {
    font-size:4vw;
    font-style: italic;
  }
}

.page-banner {
  width: 100%;
  background-size: cover;
  position: relative;
  &.skewed {
    @include skewed {
      background-color: #fff;
      bottom: -35px;
    }
  }
  &--lg {
    @extend .page-banner;
    height: 0;
    padding-bottom: sizing-function(25,9);
    .banner-title {
      font-size: 4vw;
      margin: 0;
    }
  }// page-banner--lg

  &--md {
    @extend .page-banner;
    height: 0;
    padding-bottom: sizing-function(5,1);
    &-responsive {
      @media screen and (orientation:portrait) {
        padding-bottom: sizing-function(4,1);
      }
    }
  }// .page-banner--md

  &--sm {
    @extend .page-banner;
    height: 0;
    padding-bottom: sizing-function(20,3);
  }// .page-banner--sm

  &--full {
    height: 100vh;
  }// .page-banner--full
}

// #home_parallax {
//  .layer-bg.layer{"data-type" => "parallax", "data-depth" => "0.10"}
//  .layer-1.layer{"data-type" => "parallax","data-depth" => "0.20"}
//  .layer-2.layer{"data-type" => "parallax","data-depth" => "0.50"}
//  .layer-3.layer{"data-type" => "parallax","data-depth" => "0.80"}
//  .layer-overlay.layer{"data-type" => "parallax","data-depth" => "0.85"}
//  .layer-4.layer{"data-type" => "parallax","data-depth" => "1.00"}
// }



$white: #fff;
// $heroHeight: 100vh;

#hero_mobile {
  display: block;
  background-size: cover;
  background-position: center;
  @media all and (min-width: $screen-md) {
    display: none;
  }
}
#banner_section {
  overflow: hidden;
}
.banner-container {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
}

.layer {
  position: absolute;
  left: -25%;
  width: 150%;
  transition: all 0.45s cubic-bezier(0.2,0.50,0.33,0.99);
}


@keyframes scrollBackground {
  0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}
.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #fff; /* adjust color as needed */
}

.scroll-indicator small {
  margin-top: 0.25rem;
  font-size: 0.75rem;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}


#parallax {
  position: relative;
  z-index: 0;
  width: 100%;
  min-height: 75vh;
  display: grid;
  grid-template-areas: "stack";
  background-position: top;
  background-image: url(../../img/parallax/cw_parallax_layer0_bg_v1.jpg);
  background-size: cover;
  & > * {
    grid-area: stack;
  }
}
// applied to all parallax images
.parallax-image {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}

// Clouds
.layer-1 {
  z-index: 2;
  .parallax-image {
    background-image: url(../../img/parallax/cw_parallax_layer1_clouds_v1.png);
    background-repeat: space no-repeat;
    background-size: 35%;
    animation: scrollBackground 160s linear infinite;
    background-position: center 10%;
  }
}

// Mountains
.layer-2 {
  z-index: 3;
  .parallax-image {
    background-image: url(../../img/parallax/cw_parallax_layer2_mountains_v1.png);
    background-size: 100%;
    background-position: 50% 110%;
    background-repeat: no-repeat;
    @media screen and (min-width: $screen-lg) {
      background-position-y: bottom -100px;
    }
    @media screen and (orientation:portrait) {
      background-size: 175%;
      background-position: 50% 110%;
    }
  }
}

// Clayton
.layer-3 {
  z-index: 4;
  .parallax-image {
    height: 105vh;
    background-image: url(../../img/parallax/cw_parallax_layer3_clayton_v1.png);
    background-position: 60% 100%;
    background-size: contain;
  }
}

// City
.layer-4 {
  z-index: 5;
  .parallax-image {
    height: 105vh;
    background-image: url(../../img/parallax/cw_parallax_layer4_city_v1.png);
    background-position: 50% 100%;
    background-size: 75% auto;
    @media screen and (min-width: $screen-xxl) {
      background-size: 1600px auto;
    }
  }
}

// Foreground
.layer-5 {
  z-index: 6;
  transform-style: flat;
  .parallax-image {
    
    background-image: url(../../img/parallax/cw_parallax_layer5_fg_v1.png);
    background-size: 100% auto;
    background-position: bottom center;
    perspective: none;
    backface-visibility: hidden;
    transform-style: flat;
  }
}


.speech-bubble {
  position: absolute;
  right: 10%;
  transform: translateX(-100%);
  color: $color-white;
  background-color: rgba($color-secondary, 0.9);
  width: 40%;
  max-width: 350px;
  box-sizing: border-box;
  left: 45%;
  transform: translateX(-45%);
  z-index: 10;
  top: 15vh;
  padding: 1rem 2rem;
  @media screen and (min-width: $screen-lg) {
    // right: 25%;
    padding: 2rem 4rem;
    max-width: 500px;
  }
  @media screen and (min-width: $screen-xxl) {
    // right: 35%;
  }
  @media screen and (orientation:portrait) {
    width: 40%;
    left: 30%;
    transform: translateX(-30%);
    // right: 0%;
  }

  &::after {
    display: none;
    @media screen and (min-width: $screen-sm) {
    display: block;
    }
    content: '';
    position: absolute;
    right: -39px;
    top: 25%;
    transform: translateY(-50%);
    border-left: 40px solid rgba($color-secondary, 0.9);
    border-top: 5px solid transparent;
    border-bottom: 10px solid transparent;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    font-size: 1em;
  }

  @media only screen and (min-width: $screen-md) {
    h1 {
      font-size: 2vw;
    }
    p {
      font-size: 1.25em;
    }
  }
}

// Media Queries
@media only screen and (max-width: 768px) {
  #hero {
    display: none;
  }
}