/********************************************************
/* COMPONENTS/_ALIGNMENT.SCSS
/*******************************************************/

.center-it { /* centers vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-it-y { /* centers vertically */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}