/********************************************************
/* COMPONENTS/_SECTIONS.SCSS
/*******************************************************/
.parallax {
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
}
.parallax {
  &-sm {
    @extend .parallax;
    height: 0;
    overflow: hidden;
    padding-bottom: 20%;
  }
}

.skewed {
  @include skewed;
  overflow: hidden;
  &-bottom {
    @include skewed;
    &::after {
      bottom: -20%;
      background-color: white;
    }
  }
  &-top {
    @include skewed;
    &::after {
      top: -20%;
      background-color: white;
    }
  }
  &-top-and-bottom {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 30%;
      position: absolute;
      transform: skewY(-2deg);
      bottom: -20%;
      background-color: white;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 30%;
      position: absolute;
      transform: skewY(-2deg);
      top: -20%;
      background-color: white;
    }
  }
}


section {
  overflow: hidden;
}
#about_section {
  .content-container {
    padding: $space-md;
  }
}

.content-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}