/********************************************************
/* COMPONENTS/_LISTS.SCSS
/*******************************************************/

.portfolio-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  .portfolio-list--item {
    width: 100%;
    max-width: 50%;
    @media all and (min-width: $screen-md) {
      max-width: 25%;
    }
    .item-inner {
      @extend .size--1x1;
      position: relative;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      width: 100%;
    }
    .item-front {
      // hide-initial-state
  
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: visible;
      top: 0;
      left: 0;
      pointer-events: none;
  
    }
  
    .item-back {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transform: rotateX(180deg);
      background-color: $color-secondary;
    }
  
    &:hover {
      .item-inner {
        transform: rotateX(180deg);
      }
      .item-front {
      }
  
      .item-back {
      }
    }
    &.filter-enter {
      opacity: 0;
      transform: rotateX(-90deg);
      animation: flipIn 0.8s ease forwards;
    
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.1}s;
        }
      }
    }

    &.filter-exit {
      animation: flipOut 0.4s ease forwards;
    }
  }
}

@keyframes flipIn {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes flipOut {
  from {
    opacity: 1;
    transform: rotateX(0);
  }
  to {
    opacity: 0;
    transform: rotateX(90deg);
  }
}