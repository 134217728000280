/********************************************************
/* COMPONENTS/_CASESTUDIES.SCSS
/*******************************************************/
$maxBannerImage : '../../img/case-studies/cw-max-banner.jpg';
$container-width: 1140px;

#caseStudy_max {
    .bannerArea {
        @extend .page-banner--md;
        background-color: rgba(0, 0, 0, 0.7);
        background-blend-mode: overlay;
        background-image: url($maxBannerImage);
        background-attachment: fixed;
        background-position: center;
        background-size: cover;

        .bannerContent {
            width: 100%;
            max-width: $container-width;
            margin: 0 auto;
            padding: 3rem;
            color: white;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

            img {
                width: 60%;
                max-width: 700px;
                display: block;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            h1 {
                font-size: 3.5rem;
                margin-bottom: 1rem;
            }
            
        }
    }

    .highlightSection {
        background-color: #ffffff;
        background-image: url($maxAppPromoImage);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: 60% 0%;
        padding-top: 300px;
        @media all and (min-width: $screen-lg) {
            background-color: #015464;
            padding-top: 0;
            background-size: 50%;
            background-position: 70% 50%;
        }
        @media all and (min-width: $screen-xl) {
            background-size: 800px;
            background-position: 70% 0;
        }
    }

    h2 {
        font-size: $font-lg;
        font-family: $font-stack-secondary;
        text-transform: uppercase;
        margin-bottom: $space-md;
    }
    h3 {
        font-weight: bold;
        color: $color-gray-2;
        margin-bottom: $space-lg;
    }
    p {
        margin-bottom: $space-lg;
    }
    li {
        margin-bottom: $space-md;
    }
}


#caseStudy_aiCollaboration {
    .bannerArea {
        @extend .page-banner--md;
        background-color: #f8f9fa;
        // background-image: url('../../img/case-studies/ai-collaboration-banner.jpg');
        display: flex;
        align-items: center;
        justify-content: center;
        background-attachment: fixed;
        background-position: center;

        .bannerContent {
            width: 100%;
            max-width: $container-width;
            margin: 0 auto;
            padding: 2rem;
            text-align: center;
        }
    }
}

#projectComponents {
    border-top: 1px solid $color-gray;
    @media all and (min-width: $screen-md) {
        border-top: none;
        border-left: 1px solid $color-gray;
    }
    h3 {
        font-size: $font-xs;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $font-stack-primary;
        margin-bottom: $space-md;
        color: $color-gray-2;
    }
    ul {
        list-style-type: none;
        margin-bottom: $space-lg;
        padding: 0;
    }
    li {
        margin-bottom: $space-xs;
    }
}

.process-timeline {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    h3 {
        font-size: $font-lg;
        font-weight: bold;
        font-family: $font-stack-primary;
        margin-bottom: $space-sm !important;
    }
    p {
        font-size: $font-sm;
    }

    .process-step {
        flex: 1;
        text-align: center;
        position: relative;
        padding: 2rem;

        &:not(:last-child):after {
            content: '';
            position: absolute;
            top: 2.5rem;
            right: -1rem;
            width: 2rem;
            height: 2px;
            background: #ddd;
        }

        .step-number {
            width: 3rem;
            height: 3rem;
            font-family: Helvetica, Arial, sans-serif;
            font-size: $font-lg;
            border-radius: 50%;
            background: $color-tertiary;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 1rem;
            font-weight: bold;
            box-shadow: inset 0 0 0 2px white, 0 0 0 6px rgba($color-tertiary, 0.25);
        }
    }
}

#commissionReportImgContainer {
    position: relative;
    width: 100%;
}

.commissionReportImg {
    width: 75%;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.commissionReportImg2 {
    width: 75%;
    max-width: 400px;
    display: block;
    position: absolute;
    top: 0%;
    left: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

// Add responsive styles
@media (max-width: 768px) {
    .process-timeline {
        flex-direction: column;

        .process-step {
            &:not(:last-child):after {
                display: none;
            }
        }
    }
}

.bg-white--rounded {
    background-color: #fff;
    border-radius: 10px;
}