/********************************************************
/* COMPONENTS/_SIZING.SCSS
/*******************************************************/

/* these options are available for components that should be a fixed size ratio. to work correctly, the components these are applied to should not adjust size to support child elements */


@for $i from 1 through 5 {

  .size--1x#{$i} {
    height: 0;
    width: 100%;
    padding-bottom: sizing-function(1,$i);
  }
}

@for $i from 2 through 5 {

  .size--#{$i}x1 {
    height: 0;
    width: 100%;
    padding-bottom: sizing-function($i,1);
  }
}

.size--3x2 {
  height: 0;
  width: 100%;
  padding-bottom: sizing-function(3,2);
}

.size--2x3 {
  height: 0;
  width: 100%;
  padding-bottom: sizing-function(3,2);
}

.size--4x3 { // aspect ratio for 1920x1080 monitors
  height: 0;
  width: 100%;
  padding-bottom: sizing-function(4,3);
}

.size--3x4 { // aspect ratio for 1920x1080 monitors
  height: 0;
  width: 100%;
  padding-bottom: sizing-function(3,4);
}

.size--16x9 { //common video aspect ratio
  height: 0;
  width: 100%;
  padding-bottom: sizing-function(16,9);
}