/********************************************************
/* COMPONENTS/_BUTTONS.SCSS
/*******************************************************/
// Base button classes (no actual styles, just placeholders)
@mixin button-circular(
  $width: 50px, 
  $bg-color: rgba($color-secondary, 0.8),
  $bg-color-hover: rgba($color-primary, 0.8),
  $text-color: $white,
  $text-color-hover: $white,
  $font-size: $font-xl,
  $font-family: $font-stack-icon,
) {
  width: $width;
  height: $width;
  padding: 0;
  border: none;
  border-radius: 50%;
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size;
  background-color: $bg-color;
  box-shadow: inset 0 0 0 30px $bg-color, 0 0 0 0 rgba($color-primary, 0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $text-color-hover;
    background-color: $bg-color-hover;
    box-shadow: inset 0 0 0 2px white, 0 0 0 10px rgba($color-primary, 0.25);
  }
}

// // Base button styles
// button,
// a.button-primary {
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   border: none;
//   padding: .5em 1em;
//   border-radius: 2em;
//   background-color: $color-primary;
//   font-family: $font-stack-primary;
//   font-weight: 500;
//   text-decoration: none;
//   color: white;
//   box-shadow: inset 0 0 0 30px $bg-color, 0 0 0 0 rgba($color-primary, 0);
//   transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
//   &:hover {
//     background-color: darken($color-primary, 20%);
//     color: white;
//     text-decoration: none;
//   }

//   &:disabled {
//     opacity: 0.2;
//     &:hover {
//       background-color: inherit;
//       box-shadow: none;
//     }
//   }
// }   
// Main button styles
@mixin button-main (
  $bg-color: $color-primary,
  $bg-color-hover: darken($color-primary, 20%),
  $text-color: white
){
  text-decoration: none;
  border: none;
  background-color: $bg-color;
  color: $text-color;
  padding: .5em 1em;
  box-shadow: inset 0 0 0 0px white, 0 0 0 0 rgba($bg-color, 0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $text-color;
    box-shadow: inset 0 0 0 2px white, 0 0 0 10px rgba($bg-color, 0.25);
  }
}


.button-primary {
  @include button-main();
  border-radius: 2em;
}
.button-secondary {
  @include button-main($bg-color: $color-secondary, $bg-color-hover: darken($color-secondary, 20%), $text-color: white);
  border-radius: 2em;
}

// Circular button variant
.button-circular {
  @include button-circular(
    $width: 50px,
    $bg-color: rgba(0, 0, 0, 0.8)
  );
}

// Close button variant
.button-close {
  @include button-circular(
    $width: 45px,
    $bg-color: rgba(0, 0, 0, 0.8),
    $text-color: #000
  );
}

.modal-close {
  @include button-circular(
    $width: 45px,
    $bg-color: rgba(0, 0, 0, 0.0),
    $text-color: #000
  );
  font-weight: bold;
  cursor: pointer;
}

.previous-modal,
.next-modal {
  @include button-circular(
    $width: 50px,
    $bg-color: rgba(0, 0, 0, 0.8)
  );
  &:disabled {
    opacity: 0.2;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: none;
    }
  }
}

// Carousel navigation button variant
.button-carousel-nav {
  @include button-circular(
    $width: 50px,
    $bg-color: rgba($white, 0.8),
    $bg-color-hover: rgba($color-primary, 0.8),
    $text-color: $color-link,
    $text-color-hover: $white
  );

  &:disabled {
    opacity: 0.2;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: none;
    }
  }
}

.filter-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 0 $space-lg 0;

  button {
    position: relative;
    border: none;
    background-color: transparent;
    color: $color-link;
    font-weight: 600;
    transition: all 0.3s ease;
    padding: .25rem $space-md;
    border-radius: 0;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transition: all 0.3s ease;
      transform: translateX(-50%);
    }

    &:hover {
      color: $color-secondary;
      
      &::after {
        width: calc(100% - (#{$space-md} * 2));
        background-color: rgba($color-primary, 0.5);
      }
    }

    &.selected {
      color: $color-primary;
      
      &::after {
        width: calc(100% - (#{$space-md} * 2));
        background-color: $color-primary;
      }

      &:hover {
        color: $color-secondary;
        
        &::after {
          background-color: $color-secondary;
        }
      }
    }
  }
}