// ANIMATIONS
// ------------------------------------/

// Common animation settings
$animation-duration: 0.8s;
$animation-timing: cubic-bezier(0.25, 0.46, 0.45, 0.94);

// Base styles for animated elements
[data-animate] {
  opacity: 0;
  will-change: transform, opacity;
  
  &.animate-in {
    opacity: 1;
    transition: all $animation-duration $animation-timing;
  }
}

// Slide animations
[data-animate].slide-left {
  transform: translateX(100px);
  
  &.animate-in {
    transform: translateX(0);
  }
}

[data-animate].slide-right {
  transform: translateX(-100px);
  
  &.animate-in {
    transform: translateX(0);
  }
}

// Flip animations
[data-animate].flip-horizontal {
  transform: rotateY(90deg);
  
  &.animate-in {
    transform: rotateY(0);
  }
}

[data-animate].flip-vertical {
  transform: rotateX(-90deg);
  
  &.animate-in {
    transform: rotateX(0);
  }
}

// Add delay classes
@for $i from 1 through 10 {
  [data-animate].delay-#{$i} {
    transition-delay: #{$i * 0.1}s;
  }
}
