/********************************************************
/* COMPONENTS/_MODALS.SCSS
/*******************************************************/
$modal-button--width: 50px;

/* Styles for the modal container */
.portfolio-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms ease-in-out;
  
  // Initial state (hidden)
  .portfolio-modal-panel {
    position: relative;
    background-color: #fefefe;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #888;
    min-height: 70%;
    max-width: 1200px;
    will-change: transform, opacity;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    max-height: 90vh;

    .panel-content {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 300ms ease-in-out;
      display: flex;
      flex-wrap: wrap;
      overflow-y: scroll;      

      &.transitioning {
        opacity: 0;
      }
    }
  }
  
  &.modal-hidden {
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    
    .portfolio-modal-panel {
      opacity: 0;
      transform: translateY(20px) scale(0.98);
    }
  }
  
  &.modal-visible {
    background-color: rgba(0, 0, 0, 0.75);
    
    .portfolio-modal-panel {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
}

.panel-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  background: #fefefe;

  // Ensure clickable elements work
  .modal-close,
  .modal-image-container,
  .portfolio-modal-content {
    position: relative;
    z-index: 1;
  }
}

.panel-front {
  transform: rotateY(0deg);
  z-index: 2; // Ensure front panel is clickable when visible
}

.panel-back {
  transform: rotateY(180deg);
  z-index: 1;
}

/* Styles for the modal content */
.portfolio-modal-panel {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  background-color: #fefefe;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #888;
  width: 80%;
  min-height: 70%;
  max-width: 1200px;
  will-change: transform, opacity;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  @media all and (min-width: $screen-lg) {
    width: 70%;
  }

  &.panel-visible {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  &.panel-hidden {
    transform: translateY(20px) scale(0.98);
    opacity: 0;
  }
  
  h1 {
    font-size: $font-lg;
  }
  .portfolio-modal-content {
    padding: $space-md $space-md 75px $space-md;
    display: flex;
    flex-direction: column;
    p {
      font-size: $font-sm;
    }
    @media all and (min-width: $screen-lg) {
      padding: $space-lg;
      width: 30%;
    }
  }
  .modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
    @media all and (min-width: $screen-md) {
      padding-bottom: 70%;
    }

    @media all and (min-width: $screen-lg) {
      width: 70%;
      height: 100%;
      padding-bottom: unset;
    }
  }
  .modal-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-close {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 4; // Higher than everything else
    pointer-events: auto;
    background-color: white;
    &:hover {
      background-color: $color-primary;
    }
  }

  .previous-modal {
    margin-right: $space-md;
    @media all and (min-width: $screen-lg) {
      margin-right: 0;
      position: absolute;
      left: -75px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .next-modal {
    @media all and (min-width: $screen-lg) {
      position: absolute;
      right: -75px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// Navigation buttons
.modal-navigation-buttons {
  position: absolute;
  width: 100%;
  z-index: 3; // Higher than both panel faces
  pointer-events: none; // Allow clicks to pass through the container
  top: 0;
  padding: $space-md;
  @media all and (min-width: $screen-lg) {
    top: 50%;
    transform: translateY(-50%);
  }

  button {
    pointer-events: auto; // Re-enable clicks for the buttons themselves
  }
}
